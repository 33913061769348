import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { Layout, Text } from '@ui-kitten/components';
import React, { FC, useMemo } from 'react';
import { SafeAreaView, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';
import TimerCountdown from "react-native-timer-countdown";
import Toast from 'react-native-toast-message';
import { DrawerParamList } from './Navigation';
import useProvider from './hooks/useProvider';

interface DashboardProps {
    
}

const Dashboard: FC<DashboardProps> = () => {
    const { price, circulatingSupply, liquidity, burnedTokens, balance, wallet, sifBalance, AVAXPrice, lastRebaseTime } = useProvider();
    const USDollar = useMemo(() => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: 2
        }), []);
        
    const formatter = useMemo(() => Intl.NumberFormat('en', { notation: 'standard' }), []);
    
    const navigation = useNavigation<DrawerNavigationProp<DrawerParamList>>()

    const timeRemaining = useMemo(() => {
        return (lastRebaseTime + 900000 - new Date().getTime())
    }, [lastRebaseTime])

    return (
        <Layout level='4' style={styles.container}>
                <ScrollView contentContainerStyle={{ maxWidth: 760, width: '100%', alignSelf: 'center'}} showsVerticalScrollIndicator={false} bounces={false}>
                <SafeAreaView>
                    <Text category='h5'>Dashboard</Text>
                    <Text category='s2' appearance='hint'>Stack Market Overview</Text>
                    <View style={styles.row}>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Buy Tax</Text>
                            <Text category='h5' style={{marginTop: 10}}>5%</Text>
                        </Layout>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Sell Tax</Text>
                            <Text category='h5' style={{marginTop: 10}}>6%</Text>
                        </Layout>
                    </View>
                    <View style={styles.row}>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Stack Price</Text>
                            <Text category='h5' style={{marginTop: 10}}>{USDollar.format(Number(price))}</Text>
                        </Layout>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Circulating Supply</Text>
                            <Text category='h5' style={{marginTop: 10}}>{formatter.format(Number(circulatingSupply))}</Text>
                        </Layout>
                    </View>
                    <View style={styles.row}>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Market Cap</Text>
                            <Text category='h5' style={{marginTop: 10}}>{USDollar.format(Number(price) * Number(circulatingSupply))}</Text>
                        </Layout>
                    </View>
                    <View style={styles.row}>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Liquidity</Text>
                            <Text category='h5' style={{marginTop: 10}}>{USDollar.format(Number(liquidity) * Number(price) * 2)}</Text>
                        </Layout>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>SIF Value</Text>
                            <Text category='h5' style={{marginTop: 10}}>{USDollar.format(Number(sifBalance) * Number(AVAXPrice))}</Text>
                        </Layout>
                    </View>
                    <View style={styles.row}>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Burned Tokens Value</Text>
                            <Text category='h5' style={{marginTop: 10}}>{USDollar.format(Number(burnedTokens) * Number(price))}</Text>
                        </Layout>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Tokens Burned</Text>
                            <Text category='h5' style={{marginTop: 10}}>{formatter.format(Number(burnedTokens))}</Text>
                        </Layout>
                    </View>
                    <View style={styles.row}>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Next Rebase</Text>
                            <View style={{alignSelf: 'center', padding: 24}}>
                                {timeRemaining > 0 ?<TimerCountdown
                                    initialSecondsRemaining={timeRemaining}
                                    onComplete={() => {
                                        Toast.show({
                                            text1: 'Success',
                                            text2: 'Rebase will be done in the next sell TX',
                                            type: 'success'
                                        })
                                    }}
                                    formatSecondsRemaining={(milliseconds: any) => {
                                        const remainingSec = Math.round(milliseconds / 1000);
                                        const seconds = parseInt((remainingSec % 60).toString(), 10);
                                        const minutes = parseInt(((remainingSec / 60) % 60).toString(), 10);
                                        const hours = parseInt((remainingSec / 3600).toString(), 10);
                                        const s = seconds < 10 ? '0' + seconds : seconds;
                                        const m = minutes < 10 ? '0' + minutes : minutes;
                                        let h = hours < 10 ? '0' + hours : hours;
                                        h = h === '00' ? '' : h + ':';
                                        return h + m + ':' + s;
                                    }}
                                    allowFontScaling={true}
                                    style={{ fontSize: 48, color: '#33FE0D' }}
                                    /> : 
                                    <Text category='p1' style={{ fontSize: 48, color: '#33FE0D' }}>Buffering</Text>
                                    }
                            </View>
                        </Layout>
                    </View>
                    <View style={styles.row}>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Wallet Balance</Text>
                            {wallet ? <Text category='h5' style={{marginTop: 10}}>{formatter.format(Number(balance))}</Text> : <TouchableOpacity onPress={() => navigation.navigate('Settings')}><Text category='c1' style={{marginTop: 10}}>Add Wallet</Text></TouchableOpacity>}

                        </Layout>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Wallet Value ($)</Text>
                            {wallet ? <Text category='h5' style={{marginTop: 10}}>{USDollar.format(Number(balance) * Number(price))}</Text> : <TouchableOpacity onPress={() => navigation.navigate('Settings')}><Text category='c1' style={{marginTop: 10}}>Add Wallet</Text></TouchableOpacity>}
                        </Layout>
                    </View>
                    <View style={styles.row}>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Next Rebase</Text>
                            {wallet ? <Text category='h5' style={{marginTop: 10}}>{formatter.format(Number(balance) * 0.0001867812)}</Text> : <TouchableOpacity onPress={() => navigation.navigate('Settings')}><Text category='c1' style={{marginTop: 10}}>Add Wallet</Text></TouchableOpacity>}

                        </Layout>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10, flex: 1}}>
                            <Text category='s2' appearance='hint'>Rebase Value ($)</Text>
                            {wallet ? <Text category='h5' style={{marginTop: 10}}>{USDollar.format(Number(balance) * 0.0001867812 * Number(price))}</Text> : <TouchableOpacity onPress={() => navigation.navigate('Settings')}><Text category='c1' style={{marginTop: 10}}>Add Wallet</Text></TouchableOpacity>}
                        </Layout>
                    </View>
                    <Text style={{marginVertical: 12, alignSelf: 'center'}} category='c1' appearance='hint'>Version 1.0.1</Text>
                </SafeAreaView>

                </ScrollView>
        </Layout>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 16,
    },
    row: {
        flexDirection: 'row',
        marginTop: 16,
        gap: 20,
    },
})

export default Dashboard;