import Slider from '@react-native-community/slider';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { Input, Layout, Text } from '@ui-kitten/components';
import { ethers } from 'ethers';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SafeAreaView, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { DrawerParamList } from './Navigation';
import useProvider from './hooks/useProvider';
import { calculateAPY } from './utils/calculateAPY';

interface Calculator {
    
}

const Calculator: FC<Calculator> = () => {
    const [days, setDays] = useState(90)
    const { balance, price, wallet } = useProvider();
    const [amount, setAmount] = useState(balance || '');
    const [purchasePrice, setPurchasePrice] = useState(price || '')
    const [futurePrice, setFuturePrice] = useState(price || '')

    const navigation = useNavigation<DrawerNavigationProp<DrawerParamList>>()

    const setMaxAmount = useCallback(() => {
        setAmount(balance)
    }, [balance])

    const USDollar = useMemo(() => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }), []);

    const formatter = useMemo(() => Intl.NumberFormat('en', { notation: 'compact' }), []);

    const APY = useMemo(() => calculateAPY(days), [days])

    return (
        <Layout level='4' style={styles.container}>
                <ScrollView contentContainerStyle={{ maxWidth: 760, width: '100%', alignSelf: 'center'}} showsVerticalScrollIndicator={false} bounces={false}>
                    <Text category='h5'>Calculator</Text>
                    <Text category='s2' appearance='hint'>Estimate your returns</Text>
                    <View style={styles.row}>
                        <Box title='Stack Price' value={USDollar.format(Number(price))} even={false} />
                        <Box title='APY' value='69,420%' even={true} />
                        <Layout level='2' style={[styles.box, {marginLeft: 10, marginRight: 0}]}>
                            <Text category='s2' appearance='hint'>Your Balance</Text>
                            {wallet ? <Text category='s1' style={{marginTop: 10}}>{formatter.format(Number(balance))}</Text> : <TouchableOpacity onPress={() => navigation.navigate('Settings')}><Text category='c1' style={{marginTop: 10}}>Add Wallet</Text></TouchableOpacity>}
                            
                        </Layout>
                    </View>
                    <SafeAreaView style={{flex: 1}}>
                        <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10}}>
                            <Input
                                size='large'
                                style={styles.input}
                                value={amount}
                                label={() => <Text category='s1' style={{marginBottom: 10}}>Stack Amount</Text>}
                                placeholder='0'
                                accessoryRight={() => <TouchableOpacity style={{transform: [{translateY: 5}]}} onPress={setMaxAmount}><Text category='s1' style={{marginBottom: 10}}>Balance</Text></TouchableOpacity>}
                                onChangeText={nextValue => setAmount(nextValue)}
                                keyboardType='numeric'
                            />
                            <Input
                                size='large'
                                style={[styles.input, {marginTop: 16}]}
                                value={'69,420%'}
                                disabled
                                label={() => <Text category='s1' style={{marginBottom: 10}}>APY</Text>}
                                placeholder='0'
                                onChangeText={nextValue => setAmount(nextValue)}
                                keyboardType='numeric'
                            />
                            <Input
                                size='large'
                                style={[styles.input, {marginTop: 16}]}
                                value={purchasePrice}
                                label={() => <Text category='s1' style={{marginBottom: 10}}>Stack Purchase Price ($)</Text>}
                                placeholder='0'
                                accessoryRight={() => <TouchableOpacity style={{transform: [{translateY: 5}]}} onPress={() => setPurchasePrice(price)}><Text category='s1' style={{marginBottom: 10}}>Current</Text></TouchableOpacity>}
                                onChangeText={nextValue => setPurchasePrice(nextValue)}
                                keyboardType='numeric'
                            />
                            <Input
                                size='large'
                                style={[styles.input, {marginTop: 16}]}
                                value={futurePrice}
                                label={() => <Text category='s1' style={{marginBottom: 10}}>Future Stack Price ($)</Text>}
                                placeholder='0'
                                accessoryRight={() => <TouchableOpacity style={{transform: [{translateY: 5}]}} onPress={() => setFuturePrice(price)}><Text category='s1' style={{marginBottom: 10}}>Current</Text></TouchableOpacity>}
                                onChangeText={nextValue => setFuturePrice(nextValue)}
                                keyboardType='numeric'
                            />
                            <View style={{marginTop: 14, paddingHorizontal: 10}}>
                                <Text category='s1' style={{marginBottom: 10}}>{days} Day{days > 1 ? 's' : ''}</Text>
                                <Slider
                                    style={{width: '100%'}}
                                    minimumValue={1}
                                    maximumValue={365}
                                    onValueChange={value => setDays(value)}
                                    minimumTrackTintColor="#33FE0D"
                                    maximumTrackTintColor="#000000"
                                    step={1}
                                    value={days}
                                    />
                            </View>
                            <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10, marginTop: 10}}>
                                <Text category='s2'>Your initial investment</Text>
                                <Text category='s1'>{USDollar.format(Number(amount) * Number(purchasePrice))}</Text>
                            </View>
                            <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10, marginTop: 10}}>
                                <Text category='s2'>Stack Rewards Estimation</Text>
                                <Text category='s1'>{formatter.format(APY * Number(amount))}</Text>
                            </View>
                            <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10, marginTop: 10}}>
                                <Text category='s2'>Potential return</Text>
                                <Text category='s1'>{USDollar.format((APY * Number(amount)) * Number(futurePrice))}</Text>
                            </View>

                                <Layout level='4' style={{padding: 16, borderRadius: 14, marginTop: 10}}>
                                    <Text category='s1'>Final Net Worth</Text>
                                    <Text category='h5' style={{marginTop: 10}}>{USDollar.format(APY * Number(amount) * Number(futurePrice))}</Text>
                                </Layout>
                        </Layout>
                    </SafeAreaView>
                </ScrollView>
        </Layout>
    )
}

const Box: FC<{title: string, value: string, even: boolean}> = ({title, value, even}) => {
    return (
        <Layout level='2' style={[styles.box, even && {marginLeft: 10, marginRight: 0}]}>
            <Text category='s2' appearance='hint'>{title}</Text>
            <Text category='s1' style={{marginTop: 10}}>{value}</Text>
        </Layout>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 16,
    },
    row: {
        flexDirection: 'row',
        marginTop: 16,
    },
    box: {
        padding: 14,
        flex:1,
        borderRadius: 10,
    },
    input: {
        margin: 2,
        borderRadius: 7,
    },
})

export default Calculator;