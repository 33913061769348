import AsyncStorage from '@react-native-async-storage/async-storage';
import { ethers } from 'ethers';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Toast from 'react-native-toast-message';
import ABI from '../utils/ABI.json';
import PAIR_ABI from '../utils/PAIR_ABI.json';
import ROUTER_ABI from '../utils/ROUTER_ABI.json';

const useProvider = () => {
    const provider = useMemo(() => new ethers.JsonRpcProvider('https://api.avax.network/ext/bc/C/rpc'), [])

    const contract = useMemo(() => new ethers.Contract('0x80a62e8a45f6467b499ee7edad3fd7bef0c83026', ABI, provider), [provider])
    const pair = useMemo(() => new ethers.Contract('0x516c0b41d860bd56d6b29ee59068ee4d1dfa81af', PAIR_ABI, provider), [provider])
    const router = useMemo(() => new ethers.Contract('0x60aE616a2155Ee3d9A68541Ba4544862310933d4', ROUTER_ABI, provider), [provider])

    const [balance, setBalance] = useState('')
    const [wallet, setWallet] = useState<`0x${string}`>()
    const [price, setPrice] = useState('')
    const [circulatingSupply, setCirculatingSupply] = useState('');
    const [liquidity, setLiquidity] = useState('')
    const [burnedTokens, setBurnedTokens] = useState('')
    const [sifBalance, setSifBalance] = useState('')
    const [AVAXPrice, setAVAXPrice] = useState('')
    const [lastRebaseTime, setLastRebaseTime] = useState(0)
    const getBalance = useCallback(async () => {
            const balance = await contract.balanceOf(wallet)
            setBalance(ethers.formatEther(balance))
    }, [contract, wallet])

    console.log(AVAXPrice)

    const getPrice = useCallback(async () => {
        const WAVAX = await router.WAVAX()
        const [_, AVAXprice] = await router.getAmountsOut(ethers.parseEther('1'), ["0x80a62e8a45f6467b499ee7edad3fd7bef0c83026", WAVAX])
        const [[__, USDTPrice], totalSupply, burnedTokens, SIF, [___, USDTAVAXPRICE], _lastRebaseTime] = await Promise.all([router.getAmountsOut(AVAXprice, [WAVAX, '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7']), contract.totalSupply(), contract.balanceOf('0x000000000000000000000000000000000000dEaD'), provider.getBalance('0x733c288D2a29c23ACFb0dC8252F07F73F5F5d753'), router.getAmountsOut(ethers.parseEther('1'), [WAVAX, "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"]), contract._lastRebasedTime()])
        setLiquidity(ethers.formatEther(((await pair.getReserves())[0])))
        setCirculatingSupply(ethers.formatEther(totalSupply - burnedTokens))
        setBurnedTokens(ethers.formatEther(burnedTokens))
        setPrice(ethers.formatUnits(USDTPrice, 6))
        setAVAXPrice(ethers.formatUnits(USDTAVAXPRICE, 6))
        setSifBalance(ethers.formatEther(SIF))
        setLastRebaseTime(Number(_lastRebaseTime.toString()) * 1000)
    }, [contract])

    useEffect(() => {
        if(ethers.isAddress(wallet)){
            AsyncStorage.setItem('wallet', wallet)
        }
    }, [wallet])

    useEffect(() => {
        let interval: NodeJS.Timeout;
        getPrice()
        if(ethers.isAddress(wallet)){
            getBalance()
            interval = setInterval(() => {
                getBalance()
            }, 1000 * 60 * 2)
        }
        return () => interval && clearInterval(interval)
    }, [wallet])

    useEffect(() => {
        if(!wallet){
            AsyncStorage.getItem('wallet').then((wallet: any) => {
                if(ethers.isAddress(wallet)){    
                    setWallet(wallet as `0x${string}`)
                }
            }).catch(e => console.log(e))
        }
    })

    return {provider, contract, wallet, balance, setWallet, price, circulatingSupply, liquidity, burnedTokens, sifBalance, AVAXPrice, lastRebaseTime}
}

export default useProvider;