import { MaterialIcons } from '@expo/vector-icons';
import { Input, Layout, Text } from '@ui-kitten/components';
import { ethers } from 'ethers';
import * as Clipboard from 'expo-clipboard';
import React, { FC, useEffect, useState } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import Toast from 'react-native-toast-message';
import useProvider from './hooks/useProvider';

interface SettingsProps {
    
}

const Settings: FC<SettingsProps> = () => {
    const { balance, price, wallet, setWallet } = useProvider();
    const [walletValue, setWalletValue] = useState(wallet || '')

    useEffect(() => {
        if(ethers.isAddress(wallet)){
            setWalletValue(wallet)
        }
    }, [wallet])

    const fetchCopiedText = async () => {
        const text = await Clipboard.getStringAsync();
        updateWallet(text as `0x${string}`)
    };

    const updateWallet = (w: string) => {
        try{
            setWallet(ethers.getAddress(w) as `0x${string}`)
            Toast.show({
                type: 'success',
                text1: 'Address Updated',
                text2: 'Your wallet address has been updated.',
                position: 'bottom',
            });
        } catch(e){
            setWalletValue(wallet as `0x${string}`)
            Toast.show({
                type: 'error',
                text1: 'Wrong Address',
                text2: 'The address you provided is invalid.',
                position: 'bottom',
            });
        }
    }

    return (
        <Layout level='4' style={styles.container}>
                <ScrollView contentContainerStyle={{ maxWidth: 760, width: '100%', alignSelf: 'center'}} showsVerticalScrollIndicator={false} bounces={false}>
                    <Text category='h5'>Settings</Text>
                    <Text category='s2' appearance='hint'>Configure the app</Text>
                    <Layout level='3' style={{padding: 16, borderRadius: 14, marginTop: 10}}>
                        <Input
                            size='large'
                            style={[styles.input]}
                            value={walletValue}
                            label={() => <Text category='s1' style={{marginBottom: 10}}>Your Wallet</Text>}
                            placeholder='0x...'
                            accessoryRight={() => <TouchableOpacity onPress={fetchCopiedText} style={{marginRight: 5}}><MaterialIcons name="content-paste" size={16} color='white' /></TouchableOpacity>}
                            onChangeText={nextValue => setWalletValue(nextValue as `0x${string}`)}
                            keyboardType='default'
                            onBlur={(e) => {updateWallet(e.nativeEvent.text)}}
                            />
                    </Layout>
                </ScrollView>
        </Layout>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 16,
    },
    input: {
        margin: 2,
        borderRadius: 7,
    },
})

export default Settings;