import "@ethersproject/shims";
import * as eva from '@eva-design/eva';
import { NavigationContainer } from '@react-navigation/native';
import { ApplicationProvider, Layout, Text } from '@ui-kitten/components';
import { StatusBar } from 'expo-status-bar';
import { LogBox, StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';
import Navigation from './src/Navigation';
import { myTheme } from "./src/theme";
LogBox.ignoreLogs(['Warning: Failed prop type: Invalid props.style key `tintColor` supplied to `Text`.']);
LogBox.ignoreAllLogs();
export default function App() {
  return (
    //@ts-ignore
    <NavigationContainer>
      <StatusBar style='light' />
      <ApplicationProvider {...eva} theme={myTheme}>
        <Navigation />
        <Toast/>
      </ApplicationProvider>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
