import { FontAwesome5, Ionicons, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { Divider, Drawer, DrawerItem, IndexPath, Layout, Text } from '@ui-kitten/components';
import * as WebBrowser from 'expo-web-browser';
import { Image, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Calculator from './Calculator';
import Dashboard from './Dashboard';
import Settings from './Settings';

export type DrawerParamList = {
    Calculator: undefined;
    Settings: undefined;
    Dashboard: undefined;
}

const Header = () => (
    <SafeAreaView style={{backgroundColor: '#383838'}}>
        <Image source={require('../assets/logo.png')} style={{width: 75, height: 75, alignSelf: 'center'}} />
    </SafeAreaView>
)


const Footer = () => {
    const handleOpenLink = async (link: string) => {
        try {
            await WebBrowser.openBrowserAsync(link);
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <SafeAreaView style={{backgroundColor: '#383838'}}>
            <Layout style={{ flexDirection: 'row', justifyContent: 'space-around', maxWidth: '80%', alignSelf: 'center', gap: 14}}>
                <TouchableOpacity onPress={() => handleOpenLink('https://discord.gg/qGmM8Yazdn')}>
                    <FontAwesome5 name="discord" size={18} color="#8691AA" />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleOpenLink('https://t.me/stackavax')}>
                    <FontAwesome5 name="telegram-plane" size={18} color="#8691AA" />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleOpenLink('https://stackavax.medium.com')}>
                    <FontAwesome5 name="medium-m" size={18} color="#8691AA" />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleOpenLink('https://twitter.com/stackonavax')}>
                    <FontAwesome5 name="twitter" size={18} color="#8691AA" />
                </TouchableOpacity>
            </Layout>
            <Layout style={{padding: 16, flexDirection: 'column', justifyContent: 'space-around',alignItems: 'center', maxWidth: '80%', alignSelf: 'center', gap: 5,}}>
                <Text category='s1' appearance='hint'>Powered By</Text>
                <Image source={require('../assets/avax_logo.png')} style={{width: 150, height: 30, alignSelf: 'center', opacity: 0.4}} />
            </Layout>
        </SafeAreaView>
)}

const DrawerContent = ({ navigation, state }: any) => {
    return (
        <Drawer
            header={Header}
            footer={Footer}
            selectedIndex={new IndexPath(state.index)}
            onSelect={index => navigation.navigate(state.routeNames[index.row])}>
                <DrawerItem accessoryLeft={<MaterialCommunityIcons name="monitor-dashboard" size={20} color="white" />} title='Dashboard' />
                <DrawerItem accessoryLeft={<Ionicons name="calculator-outline" size={20} color="white" />} title='Rewards Calculator' />
                <DrawerItem accessoryLeft={<Ionicons name="settings-outline" size={20} color="white" />} title='Settings' />
        </Drawer>
)};

const DrawerNavigator = createDrawerNavigator<DrawerParamList>();

function Navigation() {
    return (
        //@ts-ignore
        <DrawerNavigator.Navigator
            drawerContent={props => <DrawerContent {...props}/>}
            screenOptions={{
                headerShadowVisible: false,
                headerTitleContainerStyle: {display: 'none'},
                headerStyle: {backgroundColor: '#141414'},
                headerTintColor: '#33FE0D'
                }}>
            <DrawerNavigator.Screen name="Dashboard" component={Dashboard} />
            <DrawerNavigator.Screen name="Calculator" component={Calculator} />
            <DrawerNavigator.Screen name="Settings" component={Settings} />
        </DrawerNavigator.Navigator>
    );
}

export default Navigation;