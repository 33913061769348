import { dark } from '@eva-design/eva';

export const myTheme = {
  ...dark,
  "color-primary-100": "#E5FECE",
  "color-primary-200": "#C3FE9E",
  "color-primary-300": "#9AFE6D",
  "color-primary-400": "#73FE49",
  "color-primary-500": "#33FE0D",
  "color-primary-600": "#1ADA09",
  "color-primary-700": "#07B606",
  "color-primary-800": "#04930E",
  "color-primary-900": "#027914",
  "color-success-100": "#F3FDD6",
  "color-success-200": "#E4FCAE",
  "color-success-300": "#CEF885",
  "color-success-400": "#B8F165",
  "color-success-500": "#97E835",
  "color-success-600": "#77C726",
  "color-success-700": "#5AA71A",
  "color-success-800": "#418610",
  "color-success-900": "#2F6F0A",
  "color-info-100": "#DFECFE",
  "color-info-200": "#BFD8FE",
  "color-info-300": "#9FC1FE",
  "color-info-400": "#86ADFD",
  "color-info-500": "#5F8EFC",
  "color-info-600": "#456CD8",
  "color-info-700": "#2F4FB5",
  "color-info-800": "#1E3692",
  "color-info-900": "#122478",
  "color-warning-100": "#FEFBCC",
  "color-warning-200": "#FEF799",
  "color-warning-300": "#FEF266",
  "color-warning-400": "#FDEC40",
  "color-warning-500": "#FCE302",
  "color-warning-600": "#D8C001",
  "color-warning-700": "#B59F01",
  "color-warning-800": "#927F00",
  "color-warning-900": "#786700",
  "color-danger-100": "#FFEBD5",
  "color-danger-200": "#FFD1AC",
  "color-danger-300": "#FFB182",
  "color-danger-400": "#FF9263",
  "color-danger-500": "#FF6030",
  "color-danger-600": "#DB4023",
  "color-danger-700": "#B72618",
  "color-danger-800": "#93110F",
  "color-danger-900": "#7A090F",
  "color-basic-100": "#FFFFFF",
  "color-basic-200": "#F5F5F5",
  "color-basic-300": "#F5F5F5",
  "color-basic-400": "#D4D4D4",
  "color-basic-500": "#B3B3B3",
  "color-basic-600": "#808080",
  "color-basic-700": "#4A4A4A",
  "color-basic-800": "#383838",
  "color-basic-900": "#292929",
  "color-basic-1000": "#1F1F1F",
  "color-basic-1100": "#141414"
};